<script setup lang="ts">
    // https://nuxt.com/docs/guide/directory-structure/pages#catch-all-route

    // definePageMeta({
    //     // https://nuxt.com/docs/getting-started/routing#route-validation
    //     // maybe if we have fixed structure and we can split into content/product/bundle
    //     /*
    //      * (property) PageMeta.validate?: ((route: RouteLocationNormalized) => boolean | Promise<boolean> | Partial<NuxtError> | Promise<Partial<NuxtError>>) | undefined
    //      */
    // });

    const appStore = useAppStore();
    const config = useRuntimeConfig();
    const route = useRoute();
    const { locale, iso2Country, iso2Lang } = useLocales();
    const { hasPendingPromises } = storeToRefs(appStore);

    const { data, pending, error } = await useAsyncData('page-data', async () => {
        const data = await appStore.loadPageByUrl({
            locale: ref(config.public.LOCALE_OVERRIDE || locale.value),
            iso2Country,
            iso2Lang,
            url: `/${Array.from(route.params.slug).join('/')}`,
        });

        if (!data) throw createError({ statusCode: 404, message: 'Page not found' });
        return data;
    });
    const renderType = computed(() => {
        if (data.value?.page) {
            const types = data.value.types;
            if (types.includes('BundlePage')) return 'BundlePage';
            if (types.includes('ContentPage')) return 'ContentPage';
            if (types.includes('ProductPage')) return 'ProductPage';
        }
        return null;
    });

    watch(
        error,
        (newErr) => {
            if (newErr) console.error(newErr);
        },
        { immediate: true },
    );

    const isLoading = computed(() => !hasPendingPromises && !pending);
</script>

<template>
    <atm-loader-bike
        v-show="isLoading"
        is-page-loader />
    <mol-error
        v-if="error"
        :status-code="error.statusCode" />
    <lazy-renderer-bundle-page
        v-if="renderType === 'BundlePage'"
        v-show="!isLoading"
        :page-data="data" />
    <lazy-renderer-content-page
        v-if="renderType === 'ContentPage'"
        v-show="!isLoading"
        :page-data="data" />
    <lazy-renderer-product-page
        v-if="renderType === 'ProductPage'"
        v-show="!isLoading"
        :page-data="data" />
</template>
